import './App.css';

function App() {
  return (
    <div>
      <h1>TAO REIKI - Website!!!! - Updated</h1>
    </div>
  );
}

export default App;
